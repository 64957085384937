<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
          <!--搜索按钮-->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
                  class="mr-1"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-1"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="exportExcel"
                  class="mr-1"
              >
                <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                />
                <span class="align-middle">导出</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- Search -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <!--收款编号-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款编号"
                    label-for="receive_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="receive_no"
                      size="sm"
                      v-model="condition.receive_no"
                      placeholder="请输入收款编号"
                  />
                </b-form-group>
              </b-col>
              <!--店铺团队-->
              <b-col md="3">
                <modal-select
                    label="店铺团队"
                    type="input"
                    v-on:change="fromChildren($event,['store_name','store_id'],condition)"
                    :params="['store_name','store_id']"
                    modalName="店铺"
                    placeholder="点击选择店铺团队"
                    v-model="condition.store_name"
                >
                </modal-select>
              </b-col>
              <!--收款日期-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款日期"
                    label-for="reveive_date"
                    label-size="sm"
                >
                  <flat-pickr
                      v-model="condition.reveive_date"
                      class="form-control"
                      :config="rangeConfig"
                      placeholder="请选择收款日期"
                  />
                </b-form-group>
              </b-col>
              <!--终端客户-->
              <b-col md="3">
                <modal-select
                    label="终端客户"
                    type="input"
                    v-on:change="fromChildren($event,['channel_name','channel_id'],condition)"
                    :params="['channel_name','channel_id']"
                    modalName="终端客户"
                    placeholder="点击选择终端客户"
                    v-model="condition.channel_name"
                >
                </modal-select>
              </b-col>
              <!--核销状态-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="核销状态"
                    label-for="status"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('pro_status')"
                      v-model="condition.status"
                      class="select-size-sm"
                      placeholder="请选择核销状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(receive_no)="data">
          <b-link
              :to="{ name: 'apps-statementreceive-edit', query: { id: data.item.receive_id,type:2,srmReadonly:1,readonly:1 }}"
              target="_blank"
          >
            {{ data.item.receive_no }}
          </b-link>
        </template>

        <!-- Columns -->
        <template #cell(pro_id)="data">
          #{{ data.item.id }}
        </template>

        <template #cell(amount_verification)="data">
          {{isEmpty(data.item.ext.totalAmount) ? 0:data.item.ext.totalAmount}}
          {{isEmpty(data.item.ext.checkAmount) ? '':'(审核中：'+data.item.ext.checkAmount+')'}}
        </template>

        <template #cell(reveive_date)="data">
          {{toDate(data.item.reveive_date)}}
        </template>

        <template #cell(creator)="data">
          {{getCodeLabel('user', data.item.creator)}}
        </template>

        <template #cell(add_time)="data">
          {{toDate(data.item.add_time)}}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('pro_status', data.item.status)}`"
          >
            {{getCodeLabel('pro_status',data.item.status)}}
          </b-badge>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isEmpty } from '@core/utils/filter'
import statementproxyUseList from './statementproxyUseList'
import statementproxyStore from './statementproxyStore'
import ModalSelect from "@/views/components/modal/ModalSelect";
import Ripple from "vue-ripple-directive";
import axios from "@/libs/axios";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isShowCard:false,
      rangeConfig: {
        mode: 'range',
        maxDate: 'today',
      },
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('statementproxy')) store.registerModule('statementproxy', statementproxyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statementproxy')) store.unregisterModule('statementproxy')
    })

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData();
    }

    const fromChildren = function (params, modal, item) {
      for (let i = 0; i < modal.length; i++) {
        item[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const exportExcel = function () {
      const params = {
        receive_no:condition.value.receive_no,
        reveive_date:condition.value.reveive_date,
        store_id:condition.value.store_id,
        channel_id:condition.value.channel_id,
        status:condition.value.status,
      }
      axios({
        url: '/api/exportexcelfile/statementProxyExcel',
        method: 'get',
        params: params,
        responseType: 'blob',
      }).then(res => {
        toast.success('导出成功')
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = '现金代收' + toTime(new Date().getTime() /1000)
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data)
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      // UI
    } = statementproxyUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      searchByCondition,
      resetCondition,
      fromChildren,
      advanced_search,
      exportExcel,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      // UI
     toTime,
     toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
